header{
    background-color: #343434;
}

#alert-zone-base{
  position: fixed;
  z-index: 1000;
  width: 100%;
}
#alert-zone-warning{
  position: fixed;
  z-index: 1000;
  width: 100%;
}
#alert-zone-danger{
  position: fixed;
  z-index: 1000;
  width: 100%;
}

body{
  /* background-image: url("./containers/assets/images/backgroundImage.jpg"); */
  background: linear-gradient( to bottom, #fff, #666);
  /* background: linear-gradient( to bottom, #1e272e22, #1e272e); */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  color: #202020;
}

footer{
  background-color: #31313199;
  width: 100%;
}

.container{
  /* background-color: #000; */
  width: 50%;
  padding: 70px 10px 30px 10px;
  margin: 0 auto;
  min-height: 720px;
}

.deposit_copy{
  max-width: 50%;
  margin: 0 auto;
}

.dividend{
  max-width: 768px;
  margin: 0 auto;
}

.webmode {
  display: block;
}
.mobilemode {
  display: none;
}

#main_image{
  height: 50px;
  margin: 0 15px 0 -10px;
}
@media (max-width: 1200px) {
  .container{
    width: 80%;
    padding-top: 70px;
  }
}

@media (max-width: 576px) {

  body{
    font-size: 0.8rem;
    line-height: 1.5;
    color: #202020;
  }

  .container{
    width: 100%;
    padding: 10px 0px 30px 0px;
    min-height: 360px;
  }
  .webmode {
    display: none;
  }
  .mobilemode {
    display: block;
  }
  #main_image{
    height: 40px;
  }

  .deposit_copy{
    max-width: 100%;
    margin: 0 auto;
  }
}

#shadow{
  font-size: 100%;
  /* -webkit-transition: font-size 0.5s, font-size 0.5s; /* For Safari 3.1 to 6.0 */
  /* transition: font-size 0.5s, font-size 0.5s; */
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.35);
}

#shadow:hover{
  font-size: 200%;
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.5),
              0 15px 20px 0 rgba(0,0,0,0.05); */
  animation: progress-bar-stripes 0.35s linear infinite;
}
#none-underline:hover{
  text-decoration: none;
}
